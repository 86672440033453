import React,{ useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  const [workExperience, setWorkExperience] = useState('');

  useEffect(() => {
    const startDate = new Date('2021-02-01');
    const currentDate = new Date();

    // Calculate the difference in years, months, and days
    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    // Adjust for negative month or day values
    if (months < 0) {
      years--;
      months += 12;
    }
    if (days < 0) {
      months--;
      days += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate(); // Adjust days based on month
    }

    // Set the work experience as a formatted string
    const experienceString = `${years} years, ${months} months, ${days} days`;
    setWorkExperience(experienceString);
  }, []);
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Sri Manikandan S </span>
            from <span className="purple"> Salem, Tamil Nadu.</span>
            <br />
            I am currently employed as a <span className="purple"> Senoir software Engineer at HclTech </span> with experiance of<span className="purple"> {workExperience}</span>
            <br />
            I have completed a  <span className="purple"> Bachelor of Engineering in Electrical and Electronics </span> at RMK Engineering College, Chennai.
            </p>
            <br />
            <br />
            <p>
          Performance Achievements :
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Exceptional Performance (2023-2024)
            </li>
            <li className="about-activity">
              <ImPointRight /> Good Performance (2022-2023)
            </li>
            <li className="about-activity">
              <ImPointRight /> Distinguished Performance (2021-2022)
            </li>
          </ul>
            <p>
            Academic Achievement: 
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Bachelor of Engineering in Electrical and Electronics with 8.143 CGPA (81.43%)
            </li>
            <li className="about-activity">
              <ImPointRight /> Higher Secondary with 91.25%
            </li>
            <li className="about-activity">
              <ImPointRight /> Matriculation with 95.6%
            </li>
          </ul>
          <br />
            <br />
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
