import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/profile.png";
import Tilt from "react-parallax-tilt";
import { AiOutlineWhatsApp} from "react-icons/ai"
import { IoMdMail} from "react-icons/io";
import { FaLinkedinIn,FaTelegram } from "react-icons/fa";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I'm a passionate and dedicated Senior Software Engineer with expertise in <b className="purple"> automation, app development, and web technologies.</b>
              <br />
              <br />With a solid background in tools like
              <i>
                <b className="purple"> Power Automate, Selenium, Appium, and JavaScript </b>
              </i>, I specialize in building robust frameworks and automating workflows to drive efficiency.
              <br />
              <br />
              Whether it's  <b className="purple"> testing mobile applications or developing web-based solutions </b>, my focus remains on delivering seamless and high-quality outcomes.
              <br />
              <br />
              I’m always eager to  <b className="purple"> collaborate, learn, and contribute </b>to innovative projects that challenge my skills and push the boundaries of technology.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://wa.link/tyd7am"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineWhatsApp/>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:mailformesrimanikandan@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <IoMdMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sri-manikandan-9974b7250/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://telegram.me/mailformesrimanikandan"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaTelegram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
